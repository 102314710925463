import mitt from 'mitt'
import './polyfill'
import './$system'
import './ada'
import './variable'
import './is_bot'
import { markPoint } from 'public/src/services/mark/index.js'
import eventBus from './eventBus/core'

const emitter = mitt()

emitter.once = (type, handler) => {
  const fn = (...args) => {
    emitter.off(type, fn)
    handler(args)
  }

  emitter.on(type, fn)
}

window.appEventCenter = emitter

window.eventBus = new eventBus()

markPoint({ eventName: 'mountingJsLoaded', measureTo: 'commonJsLoaded', })
if (window.navtrack) {
  window.navtrack.navtrack_mountingJs = Date.now()
}

